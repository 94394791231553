import { RoleItemModel } from '../model/RoleDataModel.js';
import UserApiSet from '@/request/api/UserApiSet';
export default {
  data () {
    return {
      list: [],
      // 结构：[[一级菜单已选总和，一级菜单总和], [二级菜单已选总和，二级菜单总和], [菜单按钮已选总和，菜单按钮总和]]
      // 例子：[[1, 10], [2, 40], [4, 80]]
      amountArr: [[0, 0], [0, 0], [0, 0]],
      // 后端返回的全量的数据
      allPermission: []
    };
  },
  methods: {
    setChildrenSel (list, sel) {
      list.forEach(v => {
        v.sel = sel;
        if (v.children && v.children.length) {
          this.setChildrenSel(v.children, v.sel);
        }
        if (v.sel) {
          v.open = true;
        }
      });
      this.setAmountData(list);
    },
    setAmountItem (item) {
      const index = item.menuType - 1;
      if (item.sel) {
        this.amountArr[index][0] += 1;
      } else {
        this.amountArr[index][0] -= 1;
      }
    },
    setAmountData (list) {
      list.forEach(v => {
        this.setAmountItem(v);
      });
    },
    /**
     * @param listType 1: 要循环的是一级菜单，2：要循环的是二级菜单，3：要循环的是菜单按钮
     * @param myPermissionArr
     * @returns {*[]}
     */
    /* eslint-disable no-debugger */
    setPermissionList (listType = 0, myPermissionArr, pId) {
      const allPermission = this.allPermission;
      const resList = [];
      // 一级菜单循环
      allPermission.forEach(top => {
        const tModel = new RoleItemModel();
        tModel.initData(top);
        this.amountArr[0][1] += 1;
        if (top.children && top.children.length) {
          // 二级菜单循环
          top.children.forEach(mid => {
            this.amountArr[1][1] += 1;
            const mModel = new RoleItemModel();
            mModel.initData(mid);
            if (mid.children && mid.children.length) {
              // 三级菜单循环
              mid.children.forEach(small => {
                this.amountArr[2][1] += 1;
                const sModel = new RoleItemModel();
                sModel.initData(small);
                sModel.sel = !!myPermissionArr.find(v => v === sModel.id);
                if (sModel.sel) {
                  this.amountArr[2][0] += 1;
                }
                mModel.children.push(sModel);
              });
            }
            mModel.sel = !!myPermissionArr.find(v => v === mModel.id);
            if (mModel.sel) {
              this.amountArr[1][0] += 1;
            }
            tModel.children.push(mModel);
          });
        }
        tModel.sel = !!myPermissionArr.find(v => v === tModel.id);
        if (tModel.sel) {
          this.amountArr[0][0] += 1;
        }
        resList.push(tModel);
      });
      return resList;
    },
    getAllSelIds (list) {
      let resList = [];
      list.forEach(v => {
        if (v.sel) {
          resList.push(v.id);
        }
        if (v.children && v.children.length) {
          resList = [...resList, ...this.getAllSelIds(v.children)];
        }
      });
      return resList;
    },
    // 生成已选的文案
    getInfoText () {
      return `
          <p>
              <span>已选</span>
              <span><span class="strong">${this.amountArr[0][0]}</span>个一级菜单</span>
              <span><span class="strong">${this.amountArr[1][0]}</span>个二级菜单</span>
              <span><span class="strong">${this.amountArr[2][0]}</span>个菜单按钮</span>
          </p>
        `;
    },
    // 获取角色权限
    requestData (callBack) {
      this.amountArr = [[0, 0], [0, 0], [0, 0]];
      const api = UserApiSet.menuListAll;
      api.params = {};
      if (this.model.id) {
        const api2 = UserApiSet.roleInfo;
        api2.params = {
          roleId: this.model.id
        };
        Promise.all([this.$http(api), this.$http(api2)]).then(resArr => {
          this.allPermission = resArr[0].data.rows;
          this.list = this.setPermissionList(0, resArr[1].data.menus);
          this.valueChanged();
          if (callBack) { callBack(); }
        });
      } else {
        this.$http(api).then(res => {
          this.allPermission = res.data.rows;
          this.list = this.setPermissionList(0, []);
          if (callBack) { callBack(); }
        });
      }
    }
  }
};
