<!--
    Created by 程雨喵'mac on 2023/12/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：商户管理-商户列表
-->
<style lang="less">
#system-manager-role-list {
  .col-text-strong {
    color: @adornColor;
    font-weight: 600;
  }
}
</style>

<template>
  <div id="system-manager-role-list">
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        @on-click="_clickSetting"
      />
    </div>
    <div class="padding-primary-top">
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        @on-page-change="onChangePage"
      />
    </div>
    <!--弹窗：提交(新增/编辑)-->
    <v-submit
      v-if="!!dialog.submitModel"
      :model="dialog.submitModel"
      @on-close="dialog.submitModel = null"
      @on-sure="onSureSubmit"
    />
  </div>
</template>

<script>
import UserApiSet from '@/request/api/UserApiSet';
import { RoleListModel } from './model/RoleDataModel';
import vSubmit from './view/submit.vue';
export default {
  components: { vSubmit },
  data () {
    return {
      settingItems: [
        BaseSettingModel.initData('新增', 'add', 'md-add-circle').setPermission('system:role:add'),
        BaseSettingModel.initData('刷新', 'refresh', 'md-refresh-circle').setPermission('system:role:list')
      ],
      page: { current: 1, total: 0 },
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('roleName', '角色名称').setSpan({}),
        BaseTableColumn.initData('description', '角色描述').setSpan({}),
        BaseTableColumn.initData('createTime', '创建时间').setSpan({}),
        BaseTableColumn.initData('setting', '操作').setWidth(80).setAlign('center').setButtons([
          { title: '编辑', name: 'edit', permission: 'system:role:edit' }
        ], ({ name }, row, index) => {
          if (name === 'edit') {
            this.dialog.submitModel = RoleListModel.deepCopy(row);
          }
        })
      ],
      tableData: [],
      dialog: {
        detailModel: null,
        submitModel: null
      }
    };
  },
  created () {
    this.onChangePage(1);
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    onSureSubmit () {
      this.dialog.submitModel = null;
      this.onChangePage(1);
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {
      switch (key) {
      case 'add':
        this.dialog.submitModel = RoleListModel.createAdditionModel();
        break;
      case 'refresh':
        this.onChangePage(1);
        break;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = UserApiSet.roleList;
      api.params = {
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.tableData = RoleListModel.initListWithDataList(resData.rows);
        this.page.total = resData.total;
      });
      // const resList = DemoListModel.createFakeDataList();
      // this.page.total = resList.length;
      // this.tableData = DemoListModel.initListWithDataList(resList);
    },
    requestDetail () {
      // const resData = DemoDetailModel.createFakeData();
      // this.dialog.detailModel = DemoDetailModel.initModelWithData(resData);
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
