
// 权限列表中的-列表数据模型
import { isValidString1 } from '@/libs/regularUtils';

export class RoleListModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    this.roleName = '';
    this.roleKey = '';
    this.description = '';
    this.createTime = '';
  }

  /**
   * 数据具体校验-账号设置
   * @returns {*}
   */
  checkValidAccountSubmit () {
    // 专项校验
    if (this.roleName.length < 2 || this.roleName.length > 20) {
      return BaseModel.getValidRes(false, '请输入2-20位角色名称');
    } else if (!isValidString1(this.roleName)) {
      return BaseModel.getValidRes(false, '角色名称仅可汉字、数字、字母或符号组合');
    } else if (this.description.length > 40) {
      return BaseModel.getValidRes(false, '角色描述最多支持输入40位');
    }
    return BaseModel.getValidRes(true, '', {
      roleName: this.roleName,
      description: this.description
    });
  }

  static createAdditionModel () {
    return new this();
  }
}

// 配置权限中的，每个权限数据模型
export class RoleItemModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    this.menuTitle = '';
    // 1: 一级菜单，2：二级菜单，3：按钮权限
    this.menuType = 1;
    this.sel = false;
    this.path = '';
    this.open = false;
  }

  initData (resData) {
    super.initData(resData);
    if (this.menuType === 1 || this.menuType === 2) {
      this.children = [];
    }
    return this;
  }
}
